.container {
    max-width: 800px;
  }
  
  .table input {
    width: 100%;
  }
  
  button.m-1 {
    margin: 0.2rem !important;
    width: 2rem;
    height: 2rem;
    text-align: center;
    padding: 0;
    border-radius: 0.2rem;
  }
  